import React from 'react';

import * as style from './Form.module.scss';

const actionTarget = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSfqB1FPS_IJu0eFLOQc9VaMTIBE20sgFqSi0iHw_YrwaBvJQA/formResponse`;

const INPUT_NAME_MAP = {
  mail: `emailAddress`,
  name: `entry.440405885`,
  phone: `entry.70345128`,
  option: `entry.217914868`,
  description: `entry.1352157325`,
};

const OPTIONS = [
  `まずは話を聞いてみたい`,
  `見積もりをお願いしたい`,
  `確定申告の依頼をしたい`,
];

export default function Form() {
  const [email, setEmail] = React.useState(``);
  const [name, setName] = React.useState(``);
  const [phone, setPhone] = React.useState(``);
  const [option, setOption] = React.useState(``);
  const [description, setDescription] = React.useState(``);

  const handleSubmit = () => {
    // @ts-expect-error: gtag
    window.gtag(`event`, `conversion`, {
      send_to: `AW-343884781/yztKCKXsvogDEO2H_aMB`,
    });
  };

  return (
    <div className={style.wrapper}>
      <section className={style.block}>
        <h1 className={style.imgWrap}>
          <img alt="無料でお問い合わせ" className={style.img} src="/form.png" />
        </h1>
        <form action={actionTarget} method="POST" onSubmit={handleSubmit}>
          <div className={style.inputGroup}>
            <label htmlFor="email">
              <div className={style.label}>
                <span>メールアドレス</span>
                <span className={style.required}>*</span>
              </div>
              <input
                className={style.input}
                id="email"
                type="email"
                value={email}
                aria-required="true"
                required
                onChange={(v) => setEmail(v.target.value)}
                name={INPUT_NAME_MAP.mail}
              />
            </label>
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="name">
              <div className={style.label}>
                <span>お名前</span>
                <span className={style.required}>*</span>
              </div>
              <input
                className={style.input}
                id="name"
                type="text"
                value={name}
                aria-required="true"
                required
                onChange={(v) => setName(v.target.value)}
                name={INPUT_NAME_MAP.name}
              />
            </label>
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="phone">
              <div className={style.label}>
                <span>電話番号</span>
                <span className={style.required}>*</span>
              </div>
              <div className={style.note}>
                万が一メールアドレスに誤りがあった場合にのみこちらにご連絡させていただきます。
              </div>
              <input
                className={style.input}
                id="name"
                type="text"
                value={phone}
                aria-required="true"
                required
                onChange={(v) => setPhone(v.target.value)}
                name={INPUT_NAME_MAP.phone}
              />
            </label>
          </div>
          <div className={style.inputGroup}>
            <div className={style.label}>
              <span>お問い合わせ項目</span>
              <span className={style.required}>*</span>
            </div>
            <ul>
              {OPTIONS.map((v, i) => {
                const id = `option_${i}`;
                return (
                  <li key={v} className={style.option}>
                    <input
                      id={id}
                      type="radio"
                      name={INPUT_NAME_MAP.option}
                      value={v}
                      onClick={() => setOption(v)}
                      checked={option === v}
                      readOnly
                    />
                    <label className={style.label} htmlFor={id}>
                      {v}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="description">
              <div className={style.label}>
                <span>お問い合わせ内容</span>
                <span className={style.required}>*</span>
              </div>
              <div className={style.note}>
                現在の状況とご相談内容やご依頼内容を書いてください。
              </div>
              <textarea
                className={style.textarea}
                id="description"
                value={description}
                aria-required="true"
                required
                onChange={(v) => setDescription(v.target.value)}
                name={INPUT_NAME_MAP.description}
              />
            </label>
          </div>
          <button className={style.button} type="submit">
            送信
          </button>
        </form>
      </section>
    </div>
  );
}
