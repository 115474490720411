// extracted by mini-css-extract-plugin
export var wrapper = "Form-module--wrapper--hLwF7";
export var block = "Form-module--block--dvMVv";
export var imgWrap = "Form-module--imgWrap--u7+Am";
export var img = "Form-module--img--YM98h";
export var inputGroup = "Form-module--inputGroup--NCHL9";
export var label = "Form-module--label--edOfb";
export var required = "Form-module--required--kDwxo";
export var note = "Form-module--note--fzuPZ";
export var input = "Form-module--input--YOJAJ";
export var option = "Form-module--option--RAUoN";
export var textarea = "Form-module--textarea--zCXOl";
export var button = "Form-module--button--rq1z3";