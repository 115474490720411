import React from 'react';
import { Helmet } from 'react-helmet';

import Form from '@/components/Form';

const SCRIPT = `
window.dataLayer = window.dataLayer || [];
function gtag(){
window.dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'AW-343884781');
`;

const title = `お問い合わせ | チャットレディ・メールレディの確定申告・税金はチャトサポ`;
const description = `初回相談無料！チャトサポでは、税理士によるチャットレディ・メールレディの税金のご相談、確定申告の実績が豊富です。お客様満足度も最高位！税金や確定申告がわからない、不安で相談したい、バレたくない、確定申告をお願いしたい等々、迷ったらまずはチャトサポに無料相談！`;

export default function FormPage() {
  React.useEffect(() => {
    const script = document.createElement(`script`);
    script.text = SCRIPT;
    document.head.appendChild(script);
  }, []);
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `ja`,
        }}
      >
        <meta name="viewport" content="width=device-width" />
        <title>{title}</title>
        <meta content={description} name="description" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-343884781"
        />
      </Helmet>
      <Form />
    </>
  );
}
